import React, { forwardRef } from 'react';
import { styled } from '@linaria/react';

import Text from '@components/Text';
import Spinner from '@components/Spinner';

import { ITheme, useTheme } from '@src/theme';

import { getPadding, getTextColor, getBorder, getBackground, getHoveredBackground, getBorderRadius, getTextMargin, getSize } from './utils';

export type Variant = 'primary' | 'secondary' | 'tertiary' | 'borderless' | 'delete' | 'confirm';
export type ButtonType = 'normal' | 'submitForm';

interface IStyledProps {
    theme: ITheme,
    variant: Variant,
    disabled: boolean,
    width?: number | string,
    height?: number | string,
    margin?: string,
    padding?: string,
    color?: string
}

const Container = styled.button<IStyledProps>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  
  width: ${props => getSize(props.width)};
  height: ${props => getSize(props.height)};
  padding: ${props => (props.padding || getPadding(props.variant))};
  margin: ${props => props.margin || ''};
  border-radius: ${props => getBorderRadius(props.variant)};
  border: ${props => getBorder(props.disabled, props.variant, props.theme)};
  background: ${props => getBackground(props.disabled, props.variant, props.theme)};

  &:hover, &:active, &.is-active {
    background: ${props => getHoveredBackground(props.disabled, props.variant, props.theme)};
    p {
      opacity: ${props => props.disabled ? 0.3 : 1};
      color: ${props => props.disabled || props.variant === 'tertiary' ? getTextColor(props.disabled, props.variant, props.theme) : props.theme.colors.white}!important;
    }
    svg * {
      fill: ${props => (props.variant !== "tertiary" ? props.theme.colors.white : props.theme.colors.blue.default)}
    }
  }

  p {
    color: ${props => props.color || getTextColor(props.disabled, props.variant, props.theme)}!important ;
    opacity: ${props => props.disabled ? 0.3 : 1};
    margin: ${props => getTextMargin(props.variant)};
    white-space: nowrap;
  }

  * {
    cursor: pointer;
  }
`;

const SubmitContainer = styled(Container)`
    padding: 0;
    input {
        all: unset;
        padding: ${props => (props.padding || getPadding(props.variant))};
    }
`

export interface IButtonProps {
    children?: React.ReactNode;
    variant?: Variant,
    type?: ButtonType,
    width?: number | string,
    height?: number | string,
    className?: string;
    label?: string;
    textColor?: string,
    fontWeight?: number
    disabled?: boolean,
    isLoading?: boolean,
    padding?: string,
    margin?: string,
    spinnerSize?: number,
    onClick?: (e: any) => void
}

const BaseButton = forwardRef<HTMLButtonElement, IButtonProps>((props: IButtonProps, ref) => {
    const {
        label,
        children,
        disabled = false,
        variant = 'primary',
        width,
        height,
        margin,
        padding,
        textColor,
        className,
        fontWeight = 500,
        onClick,
    } = props;

    const theme: ITheme = useTheme(),
        textVariant = variant === 'confirm' ? 'bodyHeader' : 'body';
    return (
        <Container
            ref={ref}
            theme={theme}
            variant={variant}
            disabled={disabled}
            width={width}
            height={height}
            margin={margin}
            padding={padding}
            color={textColor || getTextColor(disabled, variant, theme)}
            className={className}
            onClick={onClick}
        >
            {
                children
                    ? children
                    : <Text
                        variant={textVariant}
                        fontWeight={fontWeight}>
                        {label}
                    </Text>
            }
        </Container>
    );
});


const SubmitButton = forwardRef<HTMLButtonElement, IButtonProps>((props: IButtonProps, ref) => {
    const {
        label,
        children,
        disabled = false,
        isLoading = false,
        variant = 'primary',
        type,
        textColor,
        spinnerSize = 16,
        fontWeight,
        ...rest } = props;

    const theme: ITheme = useTheme();

    return <SubmitContainer
        ref={ref}
        disabled={disabled}
        theme={theme}
        variant={variant}
        color={textColor || getTextColor(disabled, variant, theme)}
        {...rest}
    >
        {
            isLoading
                ? <Spinner size={spinnerSize} margin={rest.padding} />
                : <input type='submit' value={label}/>
        }
    </SubmitContainer>
})

const ButtonStyle = {
    normal: BaseButton,
    submitForm: SubmitButton
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>(({ type = "normal", ...rest }: IButtonProps, ref) => {
    const RenderedButton = ButtonStyle[type];
    return <RenderedButton ref={ref} {...rest} />
})

Button.defaultProps = {
    variant: 'primary',
    type: 'normal',
    disabled: false,
    fontWeight: 500
}

export default Button;
