import React from 'react';
import { Navigate } from "react-router-dom";

import Layout from '@components/Layout';
import { UserRole } from './static/permission';
import { useHtxSelector } from '@redux/hooks';
import { getIAuthByToken } from '@utils/helper';

const Authentication = React.lazy(() => import('@pages/Authentication'))
const Logout = React.lazy(() => import('@pages/Authentication/Logout'));
const CallbackOIDC = React.lazy(() => import('@pages/CallbackOIDC'));

const Dashboard = React.lazy(() => import('@pages/Dashboard'));

const AccountManagement = React.lazy(() => import('@pages/AccountManagement'));
const OverviewPage = React.lazy(() => import('@pages/AccountManagement/containers/OverviewPage'));
const ModifyUserPage = React.lazy(() => import('@pages/AccountManagement/containers/ModifyUserPage'));

const UserAdministration = React.lazy(() => import('@pages/UserAdministration'));

const HistoryLog = React.lazy(() => import('@pages/HistoryLog'));
const MyHistoryLog = React.lazy(() => import('@pages/HistoryLog/containers/MyHistoryLog'));
const HistoryLogOfOtherUsers = React.lazy(() => import('@pages/HistoryLog/containers/HistoryLogOfOtherUsers'));
const HistoryLogOfUsersRemoved = React.lazy(() => import('@pages/HistoryLog/containers/HistoryLogOfUsersRemoved'));
const AddUserReport = React.lazy(() => import('@pages/HistoryLog/containers/AddUserReport'));

const DomainAdministration = React.lazy(() => import('@pages/DomainAdmistration'));
const CurrentDomain = React.lazy(() => import('@pages/DomainAdmistration/containers/CurrentDomain'));
const AddDomain = React.lazy(() => import('@pages/DomainAdmistration/containers/AddDomain'));
const DeleteDomain = React.lazy(() => import('@pages/DomainAdmistration/containers/DeleteDomain'));
const ViewLog = React.lazy(() => import('@pages/DomainAdmistration/containers/ViewLog'));
const SetTransmissionTiming = React.lazy(() => import('@pages/DomainAdmistration/containers/SetTransmissionTiming'));
const FullTransfer = React.lazy(() => import('@pages/DomainAdmistration/containers/FullTransfer'));

const LazyLoad = ({ children }: { children: React.ReactNode }) => (
    <React.Suspense fallback={<>...</>}>
        {children}
    </React.Suspense>
)

const DefaultRoute = () => {
    const auth = useHtxSelector(state => state.auth.auth);
    return auth
        ? <Navigate to='/dashboard' replace />
        : <Navigate to='/login' replace />
}

const AuthenticatedRoute = ({ children, roles }: { children: React.ReactNode, roles?: UserRole[] }) => {
    const auth = useHtxSelector(state => state.auth.auth);
    if (!auth) {
        return <Navigate to='/' replace />;
    } else {
        return roles
            ? <RouteWithRole roles={roles}>{children}</RouteWithRole>
            : <>{children}</>
    }
}

const NonAuthenticatedRoute = ({ children }: { children: React.ReactNode }) => {
    const auth = useHtxSelector(state => state.auth.auth);
    return !auth
        ? <>{children}</>
        : <Navigate to='/' replace />
}

const RouteWithRole = ({ children, roles }: { children: React.ReactNode, roles: UserRole[] }) => {
    const token = useHtxSelector(state => state.auth.auth);
    const auth = getIAuthByToken(token);
    const isValid = roles.some(role => role === auth?.account_type);
    return <Layout>
        {isValid
            ? <LazyLoad>{children}</LazyLoad>
            : <div>You have no permission to access this page.</div>}
    </Layout>
}

const RouteChildWithRole = ({ children, roles }: { children: React.ReactNode, roles: UserRole[] }) => {
    const token = useHtxSelector(state => state.auth.auth);
    const auth = getIAuthByToken(token);
    const isValid = roles.some(role => role === auth?.account_type);
    return isValid
            ? <LazyLoad>{children}</LazyLoad>
            : <div>You have no permission to access this resource.</div>   
}

export interface IRoute {
    path: string,
    name: string,
    component: React.ReactNode,
    children?: IRoute[]
}

export const ROUTES = [
    {
        path: '/',
        name: '',
        component: <DefaultRoute />
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: <AuthenticatedRoute roles={[UserRole.Admin, UserRole.Supervisor, UserRole.Operator]}><Dashboard /></AuthenticatedRoute>
    },
    {
        path: '/dashboard/user-administration',
        name: 'User Administration',
        component: <Navigate to='view-user-access-rights' replace />
    },
    {
        path: '/dashboard/user-administration/view-user-access-rights',
        name: 'User Administration',
        component: <AuthenticatedRoute roles={[UserRole.Supervisor]}>
            <UserAdministration />
        </AuthenticatedRoute>,
    },
    {
        path: '/dashboard/account-management',
        name: 'Account Management',
        component: <AuthenticatedRoute roles={[UserRole.Admin]}><AccountManagement /></AuthenticatedRoute>,
        children: [
            {
                path: '',
                name: 'Current Accounts',
                component: <OverviewPage />
            },
            {
                path: 'create',
                name: 'Create User Account',
                component: <ModifyUserPage />
            },
            {
                path: 'edit/:accountId',
                name: 'Modify User Account',
                component: <ModifyUserPage />
            }
        ]
    },
    {
        path: '/dashboard/blacklist-administration',
        name: 'Blacklist',
        component: <AuthenticatedRoute roles={[UserRole.Supervisor, UserRole.Operator]}>
            <DomainAdministration type='blacklist' />
        </AuthenticatedRoute>,
        children: [
            {
                path: '',
                name: '',
                component: <Navigate to='current-blacklist' replace />
            },
            {
                path: 'current-blacklist',
                name: 'Current Blacklist',
                component: <CurrentDomain />
            },
            {
                path: 'current-blacklist/add',
                name: 'Add A New Blacklist',
                component: <AddDomain />
            },
            {
                path: 'current-blacklist/delete',
                name: 'Delete Blacklist',
                component: <DeleteDomain />
            },
            {
                path: 'view-blacklist-log',
                name: 'View Blacklist Log',
                component: <ViewLog />
            },
            {
                path: 'set-interval-transmission-timing',
                name: 'Set Interval Transmission Timing',
                component: <RouteChildWithRole roles={[UserRole.Supervisor]}>
                    <SetTransmissionTiming />
                </RouteChildWithRole>
            },
            {
                path: 'full-transmission',
                name: 'Full Transmission',
                component: <RouteChildWithRole roles={[UserRole.Supervisor]}>
                    <FullTransfer />
                </RouteChildWithRole>
            },
        ]
    },
    {
        path: '/dashboard/whitelist-administration',
        name: 'Whitelist',
        component: <AuthenticatedRoute roles={[UserRole.Supervisor, UserRole.Operator]}>
            <DomainAdministration type='whitelist' />
        </AuthenticatedRoute>,
        children: [
            {
                path: '',
                name: '',
                component: <Navigate to='current-whitelist' replace />
            },
            {
                path: 'current-whitelist',
                name: 'Current Whitelist',
                component: <CurrentDomain />
            },
            {
                path: 'current-whitelist/add',
                name: 'Add A New Whitelist',
                component: <RouteChildWithRole roles={[UserRole.Supervisor]}>
                    <AddDomain />
                </RouteChildWithRole>
            },
            {
                path: 'current-whitelist/delete',
                name: 'Delete Whitelist',
                component: <RouteChildWithRole roles={[UserRole.Supervisor]}>
                    <DeleteDomain />
                </RouteChildWithRole>
            },
            {
                path: 'view-whitelist-log',
                name: 'View Whitelist Log',
                component: <ViewLog />
            },
        ]
    },
    {
        path: '/dashboard/history-log',
        name: 'History Log',
        component: <AuthenticatedRoute roles={[UserRole.Admin, UserRole.Supervisor, UserRole.Operator]}>
            <HistoryLog />
        </AuthenticatedRoute>,
        children:[
            {
                path:'',
                name: '',
                component: <Navigate to='my-history-log' replace />
            },
            {
                path: 'my-history-log',
                name: 'My History Log',
                component: <MyHistoryLog/>
            },
            {
                path: 'weekly-report-history-logs-of-other-users',
                name: 'Weekly Report History Logs of Other Users',
                component: <RouteChildWithRole roles={[UserRole.Admin, UserRole.Supervisor]}>
                    <HistoryLogOfOtherUsers/>
                </RouteChildWithRole>
            },
            {
                path: 'weekly-report-history-logs-of-other-users/add-new-user-to-my-weekly-report',
                name: 'Add New User To My Weekly Report',
                component: <RouteChildWithRole roles={[UserRole.Admin, UserRole.Supervisor]}>
                    <AddUserReport/>
                </RouteChildWithRole>
            },
            {
                path: 'history-logs-of-deleted-users',
                name: 'History Logs of Deleted Users',
                component: <RouteChildWithRole roles={[UserRole.Admin]}>
                    <HistoryLogOfUsersRemoved/>
                </RouteChildWithRole>
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: <NonAuthenticatedRoute>
            <LazyLoad children={<Authentication />} />
        </NonAuthenticatedRoute>
    },
    {
        path: '/logout',
        name: 'Logout',
        component: <AuthenticatedRoute>
            <LazyLoad children={<Logout />} />
        </AuthenticatedRoute>
    },
    {
        path: '/callback-oidc',
        name: 'CallbackOIDC',
        component: <NonAuthenticatedRoute>
            <LazyLoad children={<CallbackOIDC />} />
        </NonAuthenticatedRoute>
    }
] as IRoute[];
